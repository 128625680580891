"use client";

import { SessionProvider } from "next-auth/react";
export function AuthProvider({
  children,
  session
}: {
  children: React.ReactNode;
  session: any;
}) {
  return <SessionProvider session={session} data-sentry-element="SessionProvider" data-sentry-component="AuthProvider" data-sentry-source-file="AuthContext.tsx">{children}</SessionProvider>;
}