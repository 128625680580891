"use client";

import { useTheme } from "next-themes";
import { SidebarHeader, SidebarMenu, SidebarMenuItem, SidebarMenuButton, SidebarTrigger } from "../ui/sidebar";
import Image from "next/image";
import { useEffect, useState } from "react";
export default function MainSidebarHeader() {
  const {
    theme
  } = useTheme();
  console.log("theme", theme);
  const [logoSrc, setLogoSrc] = useState("/logo/logo.png"); // Default logo

  useEffect(() => {
    // Determine the logo based on the theme or background
    const isBackgroundDark = theme === "dark"; // Adjust logic as needed
    setLogoSrc(isBackgroundDark ? "/logo/logo-alt.png" : "/logo/logo.png");
  }, [theme]); // Update logo when theme changes

  return <SidebarHeader data-sentry-element="SidebarHeader" data-sentry-component="MainSidebarHeader" data-sentry-source-file="MainSidebarHeader.tsx">
      {/* <div className="sticky right-0 top-2 -mr-5 text-right">
        <SidebarTrigger className="absolute rotate-180" />
       </div> */}
      <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-source-file="MainSidebarHeader.tsx">
        <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="MainSidebarHeader.tsx">
          <SidebarMenuButton size="lg" asChild className="no-hover" data-sentry-element="SidebarMenuButton" data-sentry-source-file="MainSidebarHeader.tsx">
            <a href="/">
              <div className="no-hover text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-lg">
                <Image src={logoSrc} alt="Spott Logo" width={40} height={40} data-sentry-element="Image" data-sentry-source-file="MainSidebarHeader.tsx" />
              </div>
              <div className="flex items-end gap-1 leading-none">
                <span className="font-polysansmedian text-xl">Spott</span>
                {/* <span className="inline">v1.0.0</span> */}
              </div>
            </a>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarHeader>;
}