"use client";

import { usePathname } from "next/navigation";
import * as React from "react";

// import { SidebarMenu } from "./SidebarMenu";
import { AudioWaveform, BadgeCheck, Bell, BookOpen, Bot, ChevronRight, ChevronsUpDown, Command, CreditCard, File, Folder, Forward, Frame, GalleryVerticalEnd, LogOut, Map, MoreHorizontal, PhoneCall, PieChart, Plus, Settings2, Sparkles, SquareTerminal, Trash2 } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "~/app/_components/ui/avatar";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "~/app/_components/ui/breadcrumb";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../ui/collapsible";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "~/app/_components/ui/dropdown-menu";
import { Separator } from "~/app/_components/ui/separator";
import { Sidebar, SidebarContent, SidebarFooter, SidebarGroup, SidebarGroupLabel, SidebarHeader, SidebarInset, SidebarMenu, SidebarMenuAction, SidebarMenuButton, SidebarMenuItem, SidebarMenuSub, SidebarMenuSubButton, SidebarMenuSubItem, SidebarProvider, SidebarRail, SidebarTrigger, useSidebar } from "~/app/_components/ui/sidebar";
import MainSidebarFooter from "./MainSidebarFooter";
import MainSidebarHeader from "./MainSidebarHeader";
import { useSession } from "next-auth/react";
import AppLayout from "../Layout/LayoutApp";
export function MainSidebar({
  children
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const isLoginOrResetPage = pathname === "/auth/login" || pathname === "/auth/reset-password";
  const {
    data: session
  } = useSession();
  const {
    state
  } = useSidebar();

  // console.log("main sidebar state: ", state);
  const data = {
    navMain: [
    // {
    //   title: "Playground",
    //   url: "#",
    //   icon: SquareTerminal,
    //   isActive: true,
    //   items: [
    //     {
    //       title: "History",
    //       url: "#",
    //     },
    //     {
    //       title: "Starred",
    //       url: "#",
    //     },
    //     {
    //       title: "Settings",
    //       url: "#",
    //     },
    //   ],
    // },
    // {
    //   title: "Models",
    //   url: "#",
    //   icon: Bot,
    //   items: [
    //     {
    //       title: "Genesis",
    //       url: "#",
    //     },
    //     {
    //       title: "Explorer",
    //       url: "#",
    //     },
    //     {
    //       title: "Quantum",
    //       url: "#",
    //     },
    //   ],
    // },
    {
      title: "Reports",
      url: "/report",
      icon: File,
      items: []
    }, ...(session?.user?.featureFlags?.hasIntegrations ? [{
      title: "Meetings",
      url: "/meetings",
      icon: PhoneCall,
      items: []
    }] : []), {
      title: "Settings",
      url: "/settings",
      icon: Settings2,
      items: [{
        title: "Profile",
        url: "/settings/forms/profile"
      }, ...(session?.user?.featureFlags?.hasIntegrations ? [{
        title: "Integrations",
        url: "/settings/forms/integrations"
      }] : []), {
        title: "Templates",
        url: "/settings/forms/templates"
      }],
      isActive: false
    }],
    projects: [
      // {
      //   name: "Reports",
      //   url: "/report",
      //   icon: File,
      // },
      // {
      //   name: "Meetings",
      //   url: "/meetings",
      //   icon: PhoneCall,
      // },
    ]
  };
  if (isLoginOrResetPage) {
    return <>{children}</>;
  }
  return (
    // <div className="flex min-h-screen flex-1">
    //   <SidebarMenu />
    //   <div className="min-h-screen flex-1 items-stretch">
    //     <main className="flex justify-center">
    //       <div className="flex w-full max-w-screen-2xl flex-col">
    //         {children}
    //       </div>
    //     </main>
    //   </div>
    // </div>
    <>
      <Sidebar collapsible="icon" variant="inset" data-sentry-element="Sidebar" data-sentry-source-file="MainSidebar.tsx">
        <MainSidebarHeader data-sentry-element="MainSidebarHeader" data-sentry-source-file="MainSidebar.tsx"></MainSidebarHeader>
        <SidebarContent data-sentry-element="SidebarContent" data-sentry-source-file="MainSidebar.tsx">
          {/* <SidebarGroup className="group-data-[collapsible=icon]:hidden">
            <SidebarGroupLabel>Platform</SidebarGroupLabel>
            <SidebarMenu>
              {data.projects.map((item) => (
                <SidebarMenuItem key={item.name}>
                  <SidebarMenuButton asChild>
                    <a href={item.url}>
                      <item.icon />
                      <span>{item.name}</span>
                    </a>
                  </SidebarMenuButton>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <SidebarMenuAction showOnHover>
                        <MoreHorizontal />
                        <span className="sr-only">More</span>
                      </SidebarMenuAction>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className="w-48 rounded-lg"
                      side="bottom"
                      align="end"
                    >
                      <DropdownMenuItem>
                        <Folder className="text-muted-foreground" />
                        <span>View Project</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <Forward className="text-muted-foreground" />
                        <span>Share Project</span>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem>
                        <Trash2 className="text-muted-foreground" />
                        <span>Delete Project</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </SidebarMenuItem>
              ))}
              <SidebarMenuItem>
                <SidebarMenuButton className="text-sidebar-foreground/70">
                  <MoreHorizontal className="text-sidebar-foreground/70" />
                  <span>More</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
           </SidebarGroup> */}
          <SidebarGroup data-sentry-element="SidebarGroup" data-sentry-source-file="MainSidebar.tsx">
            <SidebarGroupLabel data-sentry-element="SidebarGroupLabel" data-sentry-source-file="MainSidebar.tsx">Platform</SidebarGroupLabel>
            <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-source-file="MainSidebar.tsx">
              {data.navMain.map(item => <Collapsible key={item.title} asChild defaultOpen={item.isActive} className="group/collapsible">
                  <SidebarMenuItem>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton
                    // tooltip={item.title}
                    onClick={() => {
                      if (item.items?.length === 0 || state === "collapsed") {
                        window.location.href = item.url; // Redirect if no items
                      }
                    }}>
                        {item.icon && <item.icon />}
                        <span>{item.title}</span>
                        {item.items?.length > 0 &&
                      // Only show the collapsible icon if items exist
                      <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />}
                      </SidebarMenuButton>
                    </CollapsibleTrigger>
                    {item.items?.length > 0 &&
                  // Only show CollapsibleContent if items exist
                  <CollapsibleContent>
                        <SidebarMenuSub>
                          {item.items.map(subItem => <SidebarMenuSubItem key={subItem.title}>
                              <SidebarMenuSubButton asChild>
                                <a href={subItem.url}>
                                  <span>{subItem.title}</span>
                                </a>
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>)}
                        </SidebarMenuSub>
                      </CollapsibleContent>}
                  </SidebarMenuItem>
                </Collapsible>)}
            </SidebarMenu>
          </SidebarGroup>
        </SidebarContent>
        <MainSidebarFooter data-sentry-element="MainSidebarFooter" data-sentry-source-file="MainSidebar.tsx"></MainSidebarFooter>
        <SidebarRail data-sentry-element="SidebarRail" data-sentry-source-file="MainSidebar.tsx" />
      </Sidebar>
      <SidebarInset data-sentry-element="SidebarInset" data-sentry-source-file="MainSidebar.tsx">
        {/* <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem className="hidden md:block">
                  <BreadcrumbLink href="#">
                    Building Your Application
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" />
                <BreadcrumbItem>
                  <BreadcrumbPage>Data Fetching</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
         </header> */}
        {children}
      </SidebarInset>
    </>
  );
}
export default MainSidebar;