'use client';

import React, { ErrorInfo, ReactNode } from 'react';
interface ErrorBoundaryProps {
  children: ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return {
      hasError: true
    };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <h1 data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx">Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;