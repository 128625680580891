"use client";

import { usePathname } from "next/navigation";
import MainSidebar from "./MainSidebar";
import { SidebarProvider } from "../ui/sidebar";
export default function MainSidebarWrapper({
  children,
  sidebarState,
  sidebarWidth
}: {
  children: React.ReactNode;
  sidebarState: boolean;
  sidebarWidth: any;
}) {
  const pathname = usePathname();
  const isLoginOrResetPage = pathname === "/auth/login" || pathname === "/auth/reset-password";
  if (isLoginOrResetPage) {
    return <>{children}</>;
  }
  return <SidebarProvider defaultOpen={sidebarState} defaultWidth={sidebarWidth} data-sentry-element="SidebarProvider" data-sentry-component="MainSidebarWrapper" data-sentry-source-file="MainSidebarWrapper.tsx">
      <MainSidebar data-sentry-element="MainSidebar" data-sentry-source-file="MainSidebarWrapper.tsx">{children}</MainSidebar>
    </SidebarProvider>;
}